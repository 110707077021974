.annex {
    padding-bottom:6rem;
}

.annex a {
    text-decoration:none;
    color:black;
}

.annex-bar {
    display:flex;
    align-items:center;
    width:100%;
    border-bottom:1px solid #dedede;
    padding:1rem;
    box-sizing:border-box;
    background-color:white;
}

.annex-bar.main-header {
    background-color:#f8f9fa;
}

.annex-bar h3, .annex-bar p {
    font-size:1.25em;
}

.annex-bar > div {
    display:flex;
    justify-content:space-between;
    width:100%;
    max-width:500px;
}
