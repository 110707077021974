.roles {
    padding-bottom:6rem;
}

.roles a {
    text-decoration:none;
    color:black;
}

.role-bar {
    display:flex;
    align-items:center;
    width:100%;
    border-bottom:1px solid #dedede;
    padding:1rem;
    box-sizing:border-box;
    background-color:white;
}

.role-bar img {
    width:75px;
    height:auto;
    margin-right:1rem;
}

.role-bar h3 {
    font-size:1.7em;
    margin:1rem 0;
}

.role-card p {
    margin:0;
}

.role-card > div h3 {
    font-size:0.75em;
    margin-bottom:0;
}

.role-card > div img {
    max-width:50%;
    height:auto;
    margin:1rem 0;
}