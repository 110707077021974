nav {
    position:fixed;
    width:100%;
    bottom:-1px;
    display:grid;
    grid-template-columns:1fr 1fr 1fr 1fr 1fr;
    height:6rem;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
  
nav .nav-button {
    height:100%;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
}

nav > a:focus-visible {
    border:1px solid black;
    margin:0 2px 2px 2px;
}

nav > a:focus-visible > .nav-button > p {
    padding-top:1px;
}
  
nav a {
    text-decoration:none;
    margin:-1px;
}
  
.nav-button p {
    text-align:center;
    text-decoration:none;
    margin:0;
    font-size:1.2rem;
}