@import "./events.css";
@import "./home.css";
@import "./nav.css";
@import "./slider.css";
@import "./roles.css";
@import "./annexes.css";

html {
  font-size:62.5%;
  width:100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notification-popup {
  position:fixed;
  display:flex;
  flex-direction:column;
  align-items:flex-end;
  background-color:white;
  box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top:20px;
  left:50%;
  transform:translateX(-50%);
  padding:1rem 2rem;
  font-size:1.7em;
  width:clamp(20rem, 80%, 105rem);
  z-index:99;
}

.notification-popup button {
  background-color:#367fff;
  color:white;
  padding:0.5rem 3rem;
  border:1px solid black;
  width:fit-content;
  font-size:1.25em;
}

.loading {
  width:100vw; 
  height:100vh; 
  margin:0; 
  padding:0; 
  display:flex; 
  flex-direction:column; 
  justify-content:center; 
  align-items:center;
}

.App {
  background-color:#f8f9fa;
  width:100%;
  min-height:100vh;
}

.site-content {
  background-color:white;
  width:100%;
  min-height:100vh;
  max-width:750px;
  margin:0 auto;
  font-size:1.6em;
  padding:1rem;
  box-sizing:border-box;
  padding-bottom:6rem;
}

.image-wrapper {
  width:100%;
  text-align:center;
}

.site-content img {
  max-width:90%;
  max-height:400px;
  margin:1rem auto;
}

.center {
  text-align:center;
}

.icons-container {
  display:flex;
  justify-content:flex-start;
  flex-wrap:wrap;
  max-width:300px;
}

.icons-container a {
  color:black;
  text-decoration:none;
}

.icons-container div {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  margin:0.5rem;
  width:80px;
}

.icons-container div h6 {
  margin:0;
  max-width:80px;
  text-align:center;
}

.icons-container div img {
  width:40px;
  height:auto;
  max-width:40px;
}

.feedback {
  position:fixed;
  top:50%;
  right:0;
  background-color:#DA7A00;
  padding:1rem;
  transform:rotate(270deg);
  transform-origin:bottom right;
}

.feedback a {
  color:white;
  text-decoration:none;
  font-size:1.6rem;
}

@media print {
  .feedback {
    display:none;
  }
  nav {
    display:none;
  }
}

