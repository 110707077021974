.slider-container {
    width:90%;
    margin:0 auto;
}

.slick-prev:before,
.slick-next:before {
  color: lightgray !important;
  opacity: 1 !important;
}

.slick-prev, .slick-next {
    top:50vh !important;
}

.slider-card h6 {
    margin-bottom:0.5rem;
}

.slider-card p {
    margin-top:0.5rem;
}

.slider-card div {
    text-align:left;
}

.slider-card h4 {
    font-size:2em;
    text-decoration:underline;
    margin:1rem 0;
}

.slider-card h3 {
    font-size:2em;
    margin:1rem 0;
}

.next-step {
    font-size:1.5em !important;
    color:#DA7A00;
    font-style:italic;
    font-weight:bold;
}

@media only screen and (min-width: 45em) {
    .slider-card h3 {
        font-size:2.5em;
    }
}

