.events {
    padding-bottom:6rem;
}

.events a {
    text-decoration:none;
    color:black;
}

.event-bar {
    display:flex;
    width:100%;
    border-bottom:1px solid #dedede;
    padding:1rem;
    box-sizing:border-box;
    background-color:white;
}

.event-bar > div:first-child {
    display:flex;
    align-items:flex-start;
}

.event-bar > div:first-child > img {
    width:75px;
    height:auto;
    margin-right:1rem;
}

.event-bar > div:first-child h3 {
    font-size:2em;
    margin:1rem 0;
}

.event-bar > div:first-child p {
    font-size:1.4em;
}

.event-card h3 {
    color:white !important;
    text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    1px 1px 0 #000;
    margin:0.5rem auto !important;
}
