.home-page-slideshow-container {
    width:100%;
    text-align:center;
    margin:0 0 2rem 0;
}
  
.home-page-slideshow-container img {
    margin:0 auto !important;
    width:100% !important;
    max-width:478px !important;
}